export default function OnlinePaymentScreen() {
  const paymentButton = $("#payment-button");
  const paymentForm = paymentButton.parents("form");
  const loading = $("#js-loading-message");
  const processing = $("#js-processing-message");
  const UsdPaymentButton = $("#js-usd-payment-button");
  const ZarPaymentButton = $("#js-zar-payment-button");
  const currencyField = $("#js-payment-currency");
  const paymentDetail = $("#js-payment-detail");
  const errorContainer = $("#error-container");
  const errorMessage = $("#error-message");
  const cancelPaymentMessage = $("#js-cancel-payment");
  const cancelPaymentMessageReloadTimer = $("#js-cancel-payment-timer");
  const paymentAmountInput = $("#js-payment-amount");
  const selected_currency = $("#js-currency-code");
  const max_total = $("#js-max-total");
  const pending_payment_amount = $("#js-pending-payment-amount");
  const payment_currency = $("#js-payment-currency");
  const deposit_description = $("#js-deposits-description");
  const comprised_of_amount = $("#js-total-description");
  const comprised_of_div = $("#js-comprised-amount");
  const selected_max_currency = $("#js-max-selected-currency");

  let redirectUrl = "";
  let proposalGuid = "";

  const processPayment = (config) => {
    config["onCancel"] = function () {
      paymentDetail.removeClass("hidden");
    };
    redirectUrl = config["redirectUrl"];
    proposalGuid = config["callbackId"];
    config["onCompleteCallback"] = function (args) {
      flyWireComplete(args);
    };
    config["onCancel"] = function () {
      flyWireCancel();
    };
    var modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  let checkPaymentStatusCount = 0;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const checkPaymentStatus = (statusUrl, newRedirectUrl) => {
    let checkPaymentMaxTime = processing.data("check-payment-max-count");
    if (checkPaymentMaxTime == null) {
      checkPaymentMaxTime = 30;
    }

    $.ajax(statusUrl)
      .done((res) => {
        let result = res.status;
        if (result == "yes") {
          window.location.replace(newRedirectUrl);
        }
        if (result == "no") {
          checkPaymentStatusCount++;
          if (checkPaymentStatusCount < checkPaymentMaxTime) {
            sleep(1000).then(() =>
              checkPaymentStatus(statusUrl, newRedirectUrl)
            );
          } else {
            window.location.replace(newRedirectUrl);
          }
        }
      })
      .fail(() => {
        window.location.replace(newRedirectUrl);
      });
  };

  const flyWireComplete = (args) => {
    processing.removeClass("hidden");
    const queryString = new URLSearchParams(args).toString();
    const statusUrl = `${proposalGuid}/status?${queryString}`;
    const newRedirectUrl = `${redirectUrl}?${queryString}`;
    checkPaymentStatus(statusUrl, newRedirectUrl);
  };

  const countMeDown = function () {
    let CancelledCountDownTimer = cancelPaymentMessageReloadTimer.data(
      "cancelled-countdown-timer"
    );
    if (CancelledCountDownTimer == null) {
      CancelledCountDownTimer = 6;
    }

    const intervalTimer = setInterval(function () {
      cancelPaymentMessageReloadTimer.html(CancelledCountDownTimer);
      CancelledCountDownTimer--;
      if (CancelledCountDownTimer <= 0) {
        clearInterval(intervalTimer);
        window.location.reload();
      }
    }, 1000);
  };

  const flyWireCancel = () => {
    paymentButton.removeClass("hidden");
    paymentDetail.removeClass("hidden");
    loading.addClass("hidden");
    cancelPaymentMessage.removeClass("hidden");
    countMeDown();
  };

  const updatePaymentAmounts = (res) => {
    const payment_values = res.payment_values;
    selected_currency.text(payment_values.selected_currency);
    paymentAmountInput.attr("max", payment_values.max_allowed_payment_amount);
    paymentAmountInput.attr("min", payment_values.minimum_allowed_amount);
    paymentAmountInput.attr(
      "data-amount",
      payment_values.allowed_payment_amount
    );
    paymentAmountInput.val(payment_values.allowed_payment_amount);
    const max_amount = parseFloat(
      payment_values.max_allowed_payment_amount
    ).toLocaleString("en-US", { minimumFractionDigits: 2 });
    max_total.text(max_amount);
    selected_max_currency.text(payment_values.selected_payment_currency);
    pending_payment_amount.text(payment_values.pending_payment_amount);
    payment_currency.val(payment_values.selected_currency);
    const depositAmount = parseFloat(payment_values.deposit_total);
    const depositTotal = `${depositAmount.toLocaleString(
      "en-US",
      {
        minimumFractionDigits: 2,
      }
    )} ${payment_values.selected_currency}`;

    deposit_description.text(depositTotal);
    comprised_of_amount.text(payment_values.amount_description);
    const show_comprised_of_amount = comprised_of_div.attr("data-comprised-amount");
    if (payment_values.selected_payment_currency.toUpperCase() === "ZAR") {
      comprised_of_div.addClass("hidden");
    } else {
      if (show_comprised_of_amount === "true") {
      comprised_of_div.removeClass("hidden");
      }
    }
  };

  UsdPaymentButton.on("click", () => {
    UsdPaymentButton.addClass("selected");
    ZarPaymentButton.removeClass("selected");
  });

  UsdPaymentButton.on("ajax:success", (_e, res) => {
    updatePaymentAmounts(res);
  });

  ZarPaymentButton.on("click", () => {
    UsdPaymentButton.removeClass("selected");
    ZarPaymentButton.addClass("selected");
  });

  ZarPaymentButton.on("ajax:success", (_e, res) => {
    updatePaymentAmounts(res);
  });

  paymentButton.on("click", () => {
    errorContainer.addClass("hidden");
    paymentDetail.addClass("hidden");
    loading.removeClass("hidden");
  });

  paymentForm.on("ajax:success", (_e, res) => {
    loading.addClass("hidden");
    processPayment(res.config);
  });

  paymentForm.on("ajax:error", (_e, xhr) => {
    paymentButton.removeClass("hidden");
    errorContainer.removeClass("hidden");
    paymentDetail.removeClass("hidden");
    loading.addClass("hidden");
    ZarPaymentButton.click();

    let message = "Unable to process payment. Please try again later.";
    if (xhr.responseJSON && xhr.responseJSON.error) {
      message = xhr.responseJSON.error;
    }
    errorMessage.text(message);
  });

  function setComprisedAmount(res) {
    const payment_values = res.payment_values;
    const comprisedAmount = `${payment_values.amount_description.toLocaleString(
      "en-US",
      {
        minimumFractionDigits: 2,
      }
    )}`;

    comprised_of_amount.text(comprisedAmount);
  }

  function updateComprisedAmount(e) {
    const currentValue = parseFloat(e.currentTarget.value);
    const currencyValue = currencyField.val();
    const guid = paymentAmountInput.attr("data-guid");
    let minAmount = parseFloat(e.target.min);
    let maxAmount = parseFloat(e.target.max);
    if (
      currentValue > maxAmount ||
      isNaN(currentValue) ||
      currentValue < minAmount
    ) {
      errorContainer.removeClass("hidden");
      paymentButton.addClass("hidden");
      errorMessage.text(
        "That amount is not valid. Please enter a valid amount."
      );
    } else {
      errorContainer.addClass("hidden");
      paymentButton.removeClass("hidden");
    }

    $.ajax({
      url: `/online_payment/payment_chooser/${guid}/${currencyValue}/${currentValue}`,
    })
      .done((res) => {
        setComprisedAmount(res);
      })
      .error((error) => {
        errorContainer.removeClass("hidden");
        let message = "Unable to process payment. Please try again later.";
        if (error.responseJSON && error.responseJSON.error) {
          message = error.responseJSON.error;
        }
        errorMessage.text(message);
      });
  }

  //# https://www.freecodecamp.org/news/javascript-debounce-example/
  function debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  paymentAmountInput.on("keyup change", debounce(updateComprisedAmount, 600));
}
